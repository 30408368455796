<template>
  <dialog-outline v-model="showDialog" max-width="80%">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- pre- context -->
    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <template v-slot:context>
      <!-- Preview provided PDF file -->
      <v-container>
        <document-preview
          v-if="showDialog"
          :record="record"
          :open="true"
          :is-view-only="isViewOnly"
        ></document-preview>
      </v-container>
    </template>

    <template v-slot:actionsLeft>
      <!-- Check out file -->
      <base-tooltip-button
        v-if="visibleCommandCheckout"
        :disabled="!enableCommandCheckout"
        @click="checkOutFile"
      >
        <template v-slot:icon-name>
          {{ iconCheckout }}
        </template>
        <template v-slot:default>
          {{ commandCheckoutLabel }}
        </template>
        <template v-slot:toolTip>
          {{ commandCheckoutTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// design
import { iconFileCheckOut, iconPdf } from "@/design/icon/iconConst";

// model
import { eventNames } from "@/model/common/events/eventConst";
import { recordOperation } from "@/model/record/recordModel";
import { commandNames } from "@/model/common/commands/commandModel";

// services
import {
  enableRecordCommandOperation,
  fullRecordVersionName,
  visibleRecordCommandOperation
} from "@/services/record/recordService";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";

export default {
  name: "DefaultPdfViewerDialog",
  mixins: [dialogOutlineMixin, recordBannerAble],
  inheritAttrs: false,
  components: {
    DocumentPreview: () => import("@/views/document/DocumentPreview"),
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton")
  },
  props: {
    record: undefined,
    isViewOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconCheckout: iconFileCheckOut
    };
  },
  computed: {
    /**
     * Command Checkout Label
     * @return {string}
     */
    commandCheckoutLabel() {
      return "Check out the file";
    },

    /**
     * Command Checkout Tooltip
     * @return {string}
     */
    commandCheckoutTooltip() {
      return `${this.commandCheckoutLabel} and open the file in PDF Document Editor`;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return iconPdf;
    },

    /**
     * Upload Local File Dialog title
     * @return {string} Move Page Dialog title
     */
    dialogTitle() {
      return fullRecordVersionName(this.record);
    },

    /**
     * Dialog Close command label
     * @return {string}
     */
    dialogCancelCommandLabel() {
      return commandNames.close;
    },

    /**
     * Visible Command Checkout
     * @return {Boolean|boolean}
     */
    visibleCommandCheckout() {
      return (
        visibleRecordCommandOperation(this.record, recordOperation.CheckOut) &&
        !this.isViewOnly
      );
    },

    /**
     * Enable Command Checkout
     * @return {Boolean|boolean}
     */
    enableCommandCheckout() {
      return enableRecordCommandOperation(
        this.record,
        recordOperation.CheckOut
      );
    }
  },

  methods: {
    /**
     * On Check out the file
     */
    checkOutFile() {
      try {
        this.$emit(eventNames.onCheckout, this.record);
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      }
    }
  }
};
</script>
